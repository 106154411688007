import styled, { css } from 'styled-components/macro';

const Column = styled.div`
  flex-grow: 1;
  max-width: 100%;

  ${(props) => props.col && css`
    flex-grow: 0;
    width: calc((100% / ${props.theme.grid.columns}) * ${props.col});
  `};

  ${({ grow }) => grow && css`
    flex-grow: 2;
  `};

  ${({ shrink }) => shrink && css`
    flex-shrink: 2;
    flex-grow: 0;
  `};
`;

export { Column };
