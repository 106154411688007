import { motion } from 'framer-motion';
import styled, { css } from 'styled-components/macro';
import { Row } from 'components/_containers/Grid';
import { theme } from 'style/theme';

export const ItemWrapper = styled(motion.div)`
  margin-bottom: ${({ isActive }) => (isActive ? theme.size.M : theme.size.XS)};
  border-radius: 5px;
  overflow: hidden;
  transition: 250ms ease-in-out;
`;

export const ItemRow = styled(Row)`
  margin: 0;
  align-items: center;
  padding: ${theme.size.S} 0;
  background: ${theme.color.white};

  ${({ isInfoRow }) => isInfoRow && css`
    border-top: 1px solid ${theme.color.gray};
  `}

  ${({ isActionRow }) => isActionRow && css`
    padding: ${theme.size.XS} 0;
    background: ${theme.color.gray};
    box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  `}
`;

export const ItemInfo = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${theme.size.M};
  font-size: ${theme.fontSize.S};

  > div {
    margin-right: ${theme.size.S};
  }
`;
