import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './style';
import { Icon } from '../Icon';

const Button = ({
  onClick,
  isDisabled,
  isPrimary,
  isPlain,
  isActive,
  size,
  display,
  icon,
  children,
  className,
  title,
}) => (
  <ButtonWrapper
    className={className}
    onClick={onClick}
    isPrimary={isPrimary}
    isPlain={isPlain}
    isActive={isActive}
    size={size}
    display={display}
    hasIcon={!!icon}
    hasText={!!children}
    disabled={isDisabled}
    aria-label={title}
    title={title}
  >
    {icon && (<Icon size={size} name={icon} />)}
    {children}
  </ButtonWrapper>
);

Button.propTypes = {
  onClick: PropTypes.func,
  isPrimary: PropTypes.bool,
  isActive: PropTypes.bool,
  isPlain: PropTypes.bool,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  display: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

Button.defaultProps = {
  onClick: undefined,
  isPrimary: false,
  isPlain: false,
  isActive: false,
  isDisabled: false,
  size: undefined,
  display: undefined,
  icon: undefined,
  children: undefined,
  className: undefined,
  title: undefined,
};

export { Button };
