import React from 'react';
import { Router } from '@reach/router';
import { routes } from 'services/routes';
import { ListView } from 'views/ListView';
import { FormView } from 'views/FormView';
import { AppFooter } from './_components/AppFooter';
import { AppWrapper } from './_components/AppWrapper';

const App = () => (
  <AppWrapper>
    <Router style={{ gridArea: 'main', overflow: 'auto' }}>
      <ListView path={routes.list} />
      <FormView path={routes.add} />
      <FormView path={routes.edit()} />
    </Router>
    <AppFooter />
  </AppWrapper>
);

export { App };
