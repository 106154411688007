import styled, { css } from 'styled-components/macro';

export const HeadingWrapper = styled.h1`
  ${(props) => css`
    margin: ${props.margin};
    color: ${props.color};
  `};

  .prepend {
    color: ${({ theme }) => theme.color.darkGray};
    display: inline-block;
    min-width: 2em;
  }
`;
