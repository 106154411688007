import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InventoryListItemType } from 'state/inventory/types';
import { Column, Row } from 'components/_containers/Grid';
import { Button } from 'components/_elements/Button';
import { TagInput } from 'components/_form/_inputs/TagInput';
import { RatingInput } from 'components/_form/_inputs/RatingInput';
import { Input } from 'components/_form/_inputs/Input';
import { Label } from 'components/_form/Label';
import { ButtonGroup } from 'components/_elements/ButtonGroup';

const defaultValues = {
  name: '',
  quantity: '',
  tags: [],
  rating: 0,
};

const InventoryForm = ({
  defaultValues: defaultFromProps,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  const [formValues, setFormValues] = useState(defaultFromProps || defaultValues);
  const resetForm = () => setFormValues(defaultFromProps || defaultValues);

  const handleChange = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(formValues);
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            value={formValues.name}
            onChange={({ target }) => handleChange('name', target.value)}
            required
          />
        </Column>
      </Row>
      <Row>
        <Column col="4">
          <label htmlFor="quantity">
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              type="number"
              id="quantity"
              name="quantity"
              placeholder="1"
              value={formValues.quantity}
              onChange={({ target }) => handleChange('quantity', Number(target.value))}
            />
          </label>
        </Column>

        <Column col="8">
          <Label>Rating</Label>
          <RatingInput
            value={formValues.rating}
            onChange={(newRating) => handleChange('rating', newRating)}
            isInverted
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Label htmlFor="tags">Tags</Label>
          <TagInput
            value={formValues.tags}
            id="tags"
            placeholder="Add tags"
            onChange={(tags) => handleChange('tags', tags)}
            isInverted
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <ButtonGroup>
            <Button type="submit">
              {isEditMode ? 'Update' : 'Add item'}
            </Button>
            <Button isPrimary type="button" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonGroup>
        </Column>
      </Row>
    </form>
  );
};

InventoryForm.propTypes = {
  defaultValues: InventoryListItemType,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isEditMode: PropTypes.bool,
};

InventoryForm.defaultProps = {
  defaultValues: undefined,
  onSubmit: () => {},
  onCancel: () => {},
  isEditMode: false,
};

export { InventoryForm };
