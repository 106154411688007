import { theme } from 'style/theme';

const getGridSpacing = (spacing) => theme.grid.spacingMap[spacing || theme.grid.spacing];

const getGridGutter = (spacing) => `${getGridSpacing(spacing) / 2}${theme.grid.sizeUnit}`;

const rgba = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return alpha ? `rgba(${r},${g},${b},${alpha})` : `rgb(${r},${g},${b})`;
};

export const themeUtils = {
  getGridSpacing,
  getGridGutter,
  rgba,
};
