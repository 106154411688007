import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    margin-right: ${(props) => props.theme.size.XS};
    background: ${({ theme }) => theme.color.lightGray};
    color: ${({ theme }) => theme.color.dark};
    padding: 0.2em 0.75em;
    border-radius: 3px;

    &:last-child {
      margin: 0;
    }
  }
`;

const TagList = ({ tags }) => (
  <Wrapper>
    {tags.map((tag) => (
      <li key={tag}>
        {tag}
      </li>
    ))}
  </Wrapper>
);

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { TagList };
