import React from 'react';
import PropTypes from 'prop-types';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { TagInputWrapper } from './style';

const TagInput = ({
  onChange,
  id,
  validator,
  value,
  placeholder,
  isReadOnly,
  isEditable,
  isRemoveOnBackspace,
  isInverted,
  className,
  maxTags,
}) => (
  <TagInputWrapper isInverted={isInverted} className={className}>
    <ReactTagInput
      tags={value}
      id={id}
      placeholder={placeholder}
      maxTags={maxTags}
      editable={isEditable}
      readOnly={isReadOnly}
      removeOnBackspace={isRemoveOnBackspace}
      onChange={onChange}
      validator={validator}
    />
  </TagInputWrapper>
);

TagInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isEditable: PropTypes.bool,
  isRemoveOnBackspace: PropTypes.bool,
  isInverted: PropTypes.bool,
  className: PropTypes.string,
  maxTags: PropTypes.number,
  id: PropTypes.string,
};

TagInput.defaultProps = {
  validator: undefined,
  placeholder: undefined,
  isReadOnly: false,
  isEditable: true,
  isInverted: false,
  isRemoveOnBackspace: false,
  className: undefined,
  maxTags: undefined,
  id: undefined,
};

export { TagInput };
