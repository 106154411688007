const color = {
  primary: '#fca311',
  secondary: '#14213D',
  black: '#000000',
  dark: '#333333',
  gray: '#e5e5e5',
  darkGray: '#cccccc',
  lightGray: '#f4f4f4',
  light: '#f9f9f9',
  white: '#ffffff',
};

const root = {
  backgroundColor: color.lightGray,
  fontSize: '16px',
  lineHeight: '1.5',
};

const body = {
  fontFamily: "'Roboto', sans-serif",
  color: color.dark,
  fontWeight: 400,
};

const headings = {
  margin: '0 auto 1rem',
  fontFamily: "'Oswald', sans-serif",
  fontWeight: 400,
  lineHeight: 1.2,
  color: color.secondary,
  level: {
    h1: { fontSize: '2.875rem' },
    h2: { fontSize: '2.25rem' },
    h3: { fontSize: '1.25rem' },
    h4: { fontSize: '1.125rem' },
    h5: { fontSize: '1rem' },
    h6: { fontSize: '0.875rem' },
  },
};

const grid = {
  containerFixedWidth: '1200px',
  columns: 12,
  spacing: 'M',
  sizeUnit: 'rem',
  spacingMap: {
    S: 1,
    M: 1.5,
    L: 2,
  },
};

const button = {
  padding: '0.5em 0.75em',
  defaultSize: '1em',
  borderRadius: '4px',
  sizes: {
    S: '0.75em',
    M: '1em',
    L: '1.25em',
  },
  styles: {
    regular: {
      background: color.secondary,
      color: color.light,
      hoverBackground: color.black,
      hoverColor: color.light,
    },
    primary: {
      background: color.primary,
      color: color.light,
      hoverBackground: color.black,
      hoverColor: color.light,
    },
    plain: {
      background: 'transparent',
      color: color.secondary,
      hoverColor: color.black,
      hoverBackground: 'transparent',
    },
  },
};

const size = {
  XS: '0.5rem',
  S: '0.875rem',
  M: '1rem',
  L: '1.625rem',
  XL: '2.5rem',
};

const iconSize = {
  XS: 12,
  S: 16,
  M: 24,
  L: 32,
  XL: 42,
  XXL: 98,
};

const fontSize = {
  XS: '0.75rem',
  S: '0.875rem',
  M: '1rem',
  L: '2rem',
  XL: '4rem',
};

const margin = {
  S: '0.875rem',
  M: '',
  L: '2.5rem',
};

export const theme = {
  color,
  root,
  body,
  button,
  headings,
  grid,
  size,
  iconSize,
  fontSize,
  margin,
};
