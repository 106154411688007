import styled from 'styled-components/macro';
import { themeUtils } from 'utils/theme';

export const AppFooterWrapper = styled.footer.attrs(({
  spacing,
}) => ({
  gridGutter: themeUtils.getGridGutter(spacing),
}))`
  grid-area: footer;
  position: relative;
  z-index: 2;
  padding: ${({ gridGutter }) => gridGutter};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 15px -10px rgba(0, 0 , 0, 0.3);
`;
