import React from 'react';
import { routes } from 'services/routes';
import { InventoryForm } from 'views/FormView/_components/InventoryForm';
import { useInventoryState } from 'state/inventory/hook';
import { useRouter } from 'hooks/useRouter';
import { Heading } from 'components/_text/Heading';
import { ViewWrapper } from 'views/_components/ViewWrapper';
import { useTheme } from 'hooks/useTheme';
import { Column, Row } from 'components/_containers/Grid';

const FormView = () => {
  const {
    inventoryList,
    addItem,
    updateItem,
  } = useInventoryState();
  const { navigate, routeParams } = useRouter();
  const { color } = useTheme();

  // TODO: get item directly from state?
  const editItem = inventoryList.find(({ id }) => id === Number(routeParams.itemId));

  const closeEdit = () => {
    navigate(routes.list);
  };

  const handleSubmit = (item) => {
    if (editItem) updateItem(item);
    else addItem(item);

    closeEdit();
  };

  return (
    <ViewWrapper background={color.primary}>
      <Row>
        <Column>
          <Heading color={color.white} margin="0" level="1">
            {editItem ? 'Edit item' : 'Add item'}
          </Heading>
        </Column>
      </Row>
      <InventoryForm
        isEditMode={!!editItem}
        defaultValues={editItem}
        onSubmit={handleSubmit}
        onCancel={closeEdit}
      />
    </ViewWrapper>
  );
};

export { FormView };
