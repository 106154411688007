import styled, { css } from 'styled-components/macro';
import { themeUtils } from 'utils/theme';
import { theme } from 'style/theme';

export const InputStyle = css`
  display: block;
  padding: 0.75em 0;
  margin-bottom: 0.5rem;
  font-size: 1.2em;
  width: 100%;
  border: 0;
  border-bottom: 2px solid ${theme.color.gray};
  border-radius: 0;
  color: white;
  background: transparent;
  outline: 0;
  transition: all 250ms ease-in-out;

  &:focus {
    padding: 0.75em 1em;
    border-color: ${theme.color.secondary};
    background: ${themeUtils.rgba(theme.color.secondary, 0.5)};
  }

  &::placeholder {
    color: ${themeUtils.rgba(theme.color.secondary, 0.3)};
  }
`;

export const Input = styled.input`
  ${InputStyle}
`;
