import styled, { css } from 'styled-components/macro';
import { theme } from 'style/theme';

const labels = {
  checked: '"★"',
  unchecked: '"☆"',
  reset: '"⊘"',
};

export const RatingLabel = styled.label.attrs(({
  rating,
  isInverted,
}) => ({
  'aria-label': `${rating} stars`,
  htmlFor: `rating-${rating}`,
  uncheckedColor: isInverted ? theme.color.secondary : theme.color.primary,
}))`
  font-size: 1.65rem;
  text-align: right;
  cursor: pointer;
  width: 1em;
  height: 1em;
  line-height: 1;
  margin: 0 0.1rem;
  text-indent: 100rem;
  overflow: hidden;
  white-space: nowrap;

  &:before {
    text-indent: -100rem;
    display: inline-block;
    content: ${labels.unchecked};
    transition: all 250ms ease-in-out;
    color: ${({ uncheckedColor }) => uncheckedColor};
  }

  ${({ isReset }) => isReset && css`
    width: 0.7em;

    &:before {
      content: ${labels.reset} !important;
      color: ${({ isInverted }) => (isInverted ? theme.color.secondary : theme.color.darkGray)} !important;
      text-shadow: none !important;
    }
  `};
`;

export const RatingRadio = styled.input.attrs(({ rating, isChecked }) => ({
  type: 'radio',
  value: rating,
  name: 'rating',
  id: `rating-${rating}`,
  checked: isChecked,
}))`
  display: none;
`;

export const Wrapper = styled.div.attrs(({ isInverted }) => ({
  checkedColor: isInverted ? theme.color.white : theme.color.primary,
}))`
  display: inline-flex;
  height: 2.5rem;
  align-items: center;
  unicode-bidi: bidi-override;
  direction: rtl;

  ${RatingRadio}:checked ~ ${RatingLabel}:before,
  &:hover > ${RatingRadio} + ${RatingLabel}:hover ~ ${RatingLabel}:before,
  &:hover > ${RatingRadio} + ${RatingLabel}:hover:before {
    color: ${({ checkedColor }) => checkedColor};
    content: ${labels.checked};
    text-shadow: 0 0 1px #333;
  }
`;
