import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { InventoryListItemType } from 'state/inventory/types';
import { ListItem } from './_components/ListItem';
import { EmptyState } from './_components/EmptyState';
import { ListControls } from './_components/ListControls';

const InventoryList = ({
  listItems,
  onDelete,
  onUpdateItemValue,
  onSortItems,
}) => {
  const [openItems, setOpenItems] = useState([]);

  const handleToggleItem = (itemId) => (openItems.includes(itemId)
    ? setOpenItems(openItems.filter((id) => id !== itemId))
    : setOpenItems([...openItems, itemId]));

  const containerVariant = {
    enter: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariant = {
    enter: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: -10,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      <motion.div
        variants={containerVariant}
        initial="exit"
        animate="enter"
      >
        <ListControls
          listItems={listItems}
          openItems={openItems}
          onToggleItems={(items) => setOpenItems(items)}
          onSortItems={onSortItems}
        />

        {listItems.length ? listItems.map((item) => (
          <ListItem
            key={item.id}
            item={item}
            variants={itemVariant}
            isOpen={openItems.includes(item.id)}
            onToggleOpen={() => handleToggleItem(item.id)}
            onDelete={onDelete}
            onUpdateItemValue={onUpdateItemValue}
          />
        )) : <EmptyState />}
      </motion.div>
    </AnimatePresence>
  );
};

InventoryList.propTypes = {
  listItems: PropTypes.arrayOf(InventoryListItemType).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateItemValue: PropTypes.func.isRequired,
  onSortItems: PropTypes.func.isRequired,
};

export { InventoryList };
