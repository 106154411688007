import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'fridge',
  storage: localStorage,
});

const list = atom({
  key: 'inventory/list',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const inventoryState = {
  list,
};
