import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RatingLabel, RatingRadio, Wrapper } from './style';

const RatingInput = ({
  onChange,
  value,
  limit,
  isInverted,
}) => {
  const stars = [...Array(limit).keys()].reverse();

  const handleChange = ({ target }) => {
    onChange(Number(target.value));
  };

  return (
    <Wrapper isInverted={isInverted} className={isInverted && 'is-inverted'}>
      {stars.map((num) => {
        const rating = num + 1;
        return (
          <Fragment key={rating}>
            <RatingRadio isChecked={rating === value} rating={rating} onChange={handleChange} />
            <RatingLabel rating={rating} isInverted={isInverted}>
              {rating}
            </RatingLabel>
          </Fragment>
        );
      })}
      <RatingRadio rating="0" onChange={handleChange} />
      <RatingLabel isReset rating="0" isInverted={isInverted}>
        0
      </RatingLabel>
    </Wrapper>
  );
};

RatingInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  limit: PropTypes.number,
  isInverted: PropTypes.bool,
};

RatingInput.defaultProps = {
  onChange: {},
  limit: 5,
  value: undefined,
  isInverted: false,
};

export { RatingInput };
