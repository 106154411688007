import styled, { css } from 'styled-components/macro';
import { theme } from 'style/theme';

const {
  button,
  button: {
    styles,
  },
} = theme;

const buttonBase = css`
  display: ${({ display }) => display || 'inline-block'};
  padding: ${button.padding};
  font-size: ${({ size }) => (size ? button.sizes[size] : button.defaultSize)};
  border: 0;
  outline: 0;
  cursor: pointer;
  border-radius: ${button.borderRadius};
  transition: all 250ms ease-in-out;
`;

const defaultStyle = css`
  color: ${styles.regular.color};
  background: ${styles.regular.background};

  &:hover {
    color: ${styles.regular.hoverColor};
    background: ${styles.regular.hoverBackground};
  }
`;

const primaryStyle = css`
  ${({ isPrimary }) => isPrimary && css`
    color: ${styles.primary.color};
    background: ${styles.primary.background};

    &:hover {
      color: ${styles.primary.hoverColor};
      background: ${styles.primary.hoverBackground};
    }
  `}
`;

const plainStyle = css`
  ${({ isPlain }) => isPlain && css`
    color: ${styles.plain.color};
    background: ${styles.plain.background};
    padding: 0;

    &:hover {
      color: ${styles.plain.hoverColor};
      background: ${styles.plain.hoverBackground};
    }
  `}
`;

const activeStyle = css`
  opacity: ${({ isActive }) => isActive && 0.3};
`;

const disabledStyle = css`
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const iconStyles = css`
  ${({ hasIcon, hasText }) => hasIcon && css`
    display: inline-flex;
    align-items: center;
    padding-left: ${hasText && '0.65em'};

    span {
      margin-right: ${hasText && '0.35em'};
    }
  `}
`;

export const ButtonWrapper = styled.button`
  ${buttonBase}
  ${defaultStyle}
  ${primaryStyle}
  ${plainStyle}
  ${activeStyle}
  ${iconStyles}
  ${disabledStyle}
`;
