import styled from 'styled-components/macro';

const AppWrapper = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "main"
    "footer";
`;

export { AppWrapper };
