import styled from 'styled-components/macro';
import { themeUtils } from 'utils/theme';

const Container = styled.div.attrs(({
  spacing,
}) => ({
  gridGutter: themeUtils.getGridGutter(spacing),
}))`
  max-width: ${(props) => (props.isFixed ? props.theme.grid.containerFixedWidth : '100%')};
  margin-left: auto;
  margin-right: auto;
  padding: ${({ gridGutter }) => gridGutter};
`;

export { Container };
