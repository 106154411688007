import { useRecoilValue, useSetRecoilState } from 'recoil';
import { arrayUtils } from 'utils/array';
import { inventoryState } from './atom';

const useInventoryState = () => {
  const inventoryList = useRecoilValue(inventoryState.list);
  const setInventoryList = useSetRecoilState(inventoryState.list);

  const addItem = (item) => {
    setInventoryList((oldInventory) => [
      ...oldInventory,
      {
        id: Date.now(),
        ...item,
      },
    ]);
  };

  const updateItem = (item) => {
    const itemIndex = inventoryList.findIndex(({ id }) => id === item.id);
    const updatedList = arrayUtils.replaceItemAtIndex(inventoryList, itemIndex, item);

    setInventoryList(updatedList);
  };

  const updateItemValue = (item, key, value) => {
    const itemIndex = inventoryList.findIndex(({ id }) => id === item.id);
    const updatedList = arrayUtils.replaceItemAtIndex(inventoryList, itemIndex, {
      ...item,
      [key]: value,
    });

    setInventoryList(updatedList);
  };

  const deleteItem = (itemId) => {
    setInventoryList(inventoryList.filter(({ id }) => id !== itemId));
  };

  // TODO: not implemented yet
  const sortItems = () => null;

  return {
    inventoryList,
    updateItem,
    updateItemValue,
    addItem,
    deleteItem,
    sortItems,
  };
};

export { useInventoryState };
