import React from 'react';
import { useInventoryState } from 'state/inventory/hook';
import { ViewWrapper } from 'views/_components/ViewWrapper';
import { InventoryList } from './_components/InventoryList';

const ListView = () => {
  const {
    inventoryList,
    deleteItem,
    updateItem,
    updateItemValue,
    sortItems,
  } = useInventoryState();

  return (
    <ViewWrapper>
      <InventoryList
        listItems={inventoryList}
        onDelete={deleteItem}
        onUpdate={updateItem}
        onUpdateItemValue={updateItemValue}
        onSortItems={sortItems}
      />
    </ViewWrapper>
  );
};

export { ListView };
