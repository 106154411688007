import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { routes } from 'services/routes';
import { InventoryListItemType } from 'state/inventory/types';
import { useRouter } from 'hooks/useRouter';
import { TagList } from 'views/ListView/_components/InventoryList/_components/ListItem/_components/TagList';
import { Column } from 'components/_containers/Grid';
import { Button } from 'components/_elements/Button';
import { ButtonGroup } from 'components/_elements/ButtonGroup';
import { Icon } from 'components/_elements/Icon';
import { Heading } from 'components/_text/Heading';
import { useTheme } from 'hooks/useTheme';
import { ItemInfo, ItemRow, ItemWrapper } from './style';

const ListItem = ({
  item,
  isOpen,
  onToggleOpen,
  onUpdateItemValue,
  onDelete,
  variants,
}) => {
  const { navigate } = useRouter();
  const { color } = useTheme();

  const subtractCount = (listItem) => {
    onUpdateItemValue(listItem, 'quantity', item.quantity - 1);
  };

  const handleEdit = () => {
    navigate(routes.edit(item.id));
  };

  useEffect(() => {
    if (item.quantity < 1) {
      onToggleOpen(item.id);
      onDelete(item.id);
    }
  }, [item, onDelete, onToggleOpen]);

  return (
    <ItemWrapper isActive={isOpen} variants={variants}>
      <ItemRow onClick={onToggleOpen}>
        <Column shrink>
          <Heading level="3" margin="0" color={color.darkGray}>
            {`${item.quantity}x`}
          </Heading>
        </Column>
        <Column>
          <Heading margin="0" level="3">
            {item.name}
          </Heading>
        </Column>
        <Column shrink>
          <Button
            onClick={onToggleOpen}
            isActive={isOpen}
            isPlain
            icon={isOpen ? 'X' : 'DotsHorizontalRounded'}
            title={`Show more details about ${item.name}`}
          />
        </Column>
      </ItemRow>

      {isOpen && (
        <>
          {(!!item.rating || !!item.tags.length) && (
            <ItemRow isInfoRow>
              <Column>
                {item.rating > 0 && (
                  <ItemInfo>
                    <Icon name="Star" title="Rating" />
                    {item.rating}
                  </ItemInfo>
                )}
                {!!item.tags.length && (
                  <ItemInfo>
                    <Icon name="PurchaseTag" title="Tags" />
                    <TagList tags={item.tags} />
                  </ItemInfo>
                )}
              </Column>
            </ItemRow>
          )}
          <ItemRow isActionRow>
            <Column>
              <ButtonGroup>
                <Button isPrimary onClick={() => subtractCount(item)} icon="Beer" title="Remove one" />
                <Button onClick={handleEdit} icon="Edit" title="Edit item" />
              </ButtonGroup>
            </Column>
            <Column shrink>
              <Button isPlain onClick={() => onDelete(item.id)} icon="Trash" title="Delete item" />
            </Column>
          </ItemRow>
        </>
      )}
    </ItemWrapper>
  );
};

ListItem.propTypes = {
  item: InventoryListItemType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  onUpdateItemValue: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  variants: PropTypes.object // eslint-disable-line
};

export { ListItem };
