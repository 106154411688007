import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { themeUtils } from 'utils/theme';
import { Column } from '../Column';

const Row = styled.div.attrs((props) => ({
  gridGutter: themeUtils.getGridGutter(props.spacing),
  flow: props.flow || 'row',
}))`
  display: flex;
  flex-flow: ${({ flow }) => `${flow} wrap`};
  margin-bottom: ${({ noMargin, gridGutter }) => !noMargin && gridGutter};
  margin-top: ${({ noMargin, gridGutter }) => !noMargin && gridGutter};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};

  > ${Column} {
    padding-left: ${({ gridGutter }) => gridGutter};
    padding-right: ${({ gridGutter }) => gridGutter};
    flex-grow: ${({ flexGrow }) => flexGrow}
  }

  ${({ col, theme }) => col && css`
    > ${Column} {
      flex-grow: 0;
      width: calc((100% / ${theme.grid.columns}) * ${col});
    }
  `};
`;

Row.propTypes = {
  alignItems: PropTypes.string,
  noMargin: PropTypes.bool,
};

export { Row };
