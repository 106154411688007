import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { theme } from 'style/theme';
import { Container } from 'components/_containers/Grid';

const Wrapper = styled.main`
  height: 100%;
  background: ${({ background }) => background || theme.color.lightGray};
`;

const ViewWrapper = ({
  children,
  background,
}) => (
  <Wrapper background={background}>
    <Container isFixed>
      {children}
    </Container>
  </Wrapper>
);

ViewWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
};

ViewWrapper.defaultProps = {
  background: undefined,
};

export { ViewWrapper };
