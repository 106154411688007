import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { InventoryListItemType } from 'state/inventory/types';
import { Button } from 'components/_elements/Button';

const ControlButton = styled(Button).attrs(() => ({
  isPlain: true,
}))``;

const ListControls = ({
  listItems,
  openItems,
  onToggleItems,
  // onSortItems,
}) => {
  const toggleAllItems = (isOpen) => onToggleItems(isOpen
    ? listItems.reduce((acc, { id }) => [...acc, id], [])
    : []);

  const isAllItemsOpen = listItems.length === openItems.length;

  return (
    <div>
      <ControlButton
        isDisabled={isAllItemsOpen}
        title="Open all"
        onClick={toggleAllItems}
        icon="ChevronsDown"
      />
      <ControlButton
        isDisabled={openItems.length < 1}
        title="Close all"
        onClick={() => toggleAllItems(false)}
        icon="ChevronsUp"
      />
      {/* <ControlButton
        title="Sort descending"
        onClick={() => onSortItems('desc')}
        icon="SortAZ"
      />
      <ControlButton
        title="Sort ascending"
        onClick={() => onSortItems('asc')}
        icon="SortZA"
      /> */}
    </div>
  );
};

ListControls.propTypes = {
  listItems: PropTypes.arrayOf(InventoryListItemType).isRequired,
  openItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  onToggleItems: PropTypes.func.isRequired,
  // onSortItems: PropTypes.func.isRequired,
};

export { ListControls };
