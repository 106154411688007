import React from 'react';
import PropTypes from 'prop-types';
import { HeadingWrapper } from './style';

const Heading = ({
  level,
  color,
  children,
  className,
  margin,
  prepend,
}) => (
  <HeadingWrapper
    as={`h${level}`}
    level={level}
    color={color}
    className={className}
    margin={margin}
  >
    {prepend && (
      <span className="prepend">{prepend}</span>
    )}
    {children}
  </HeadingWrapper>
);

Heading.propTypes = {
  level: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  margin: PropTypes.string,
  prepend: PropTypes.string,
};

Heading.defaultProps = {
  level: 1,
  color: undefined,
  className: undefined,
  margin: undefined,
  prepend: undefined,
};

export {
  Heading,
};
