import { css, createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'styled-normalize';
import { theme } from './theme';

const { root, body, headings } = theme;

const headingsCss = () => {
  let styles = '';

  const levelsLength = Object.keys(headings.level).length;

  for (let i = 1; i <= levelsLength; i += 1) {
    styles += `
      h${i} {
        font-size: ${headings.level[`h${i}`].fontSize};
        font-family: ${headings.level[`h${i}`].fontFamily || headings.fontFamily};
        font-weight: ${headings.level[`h${i}`].fontWeight || headings.fontWeight};
        margin: ${headings.level[`h${i}`].margin || headings.margin};
        color: ${headings.level[`h${i}`].color || headings.color};
      }
    `;
  }

  return css`${styles}`;
};

const GlobalStyle = createGlobalStyle`
  ${normalize}

  :root {
    background-color: ${root.backgroundColor};
    font-size: ${root.fontSize};
    line-height: ${root.lineHeight};
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    font-family: ${body.fontFamily};
    font-size: 1rem;
    font-weight: ${body.fontWeight};
    color: ${body.color};
    margin: 0;
  }

  ${headingsCss}
`;

export { GlobalStyle };
