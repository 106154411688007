import {
  useNavigate,
  useParams,
} from '@reach/router';

const useRouter = () => {
  const navigate = useNavigate();
  const routeParams = useParams();

  return {
    navigate,
    routeParams,
  };
};

export { useRouter };
