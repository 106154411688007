import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from 'components/_elements/Icon';
import { Heading } from 'components/_text/Heading';

const Wrapper = styled.div`
  display: flex;
  height: 80vh;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  
  && > * {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

const EmptyState = () => (
  <Wrapper>
    <Icon name="Beer" size="XXL" />
    <Heading level="2">Your fridge is empty.</Heading>
  </Wrapper>
);

export { EmptyState };
