import styled from 'styled-components/macro';
import { theme } from 'style/theme';
import { InputStyle } from '../Input';

export const TagInputWrapper = styled.div`
  .react-tag-input {
    padding: 0;
    border: 0;
    background: none;
  }

  .react-tag-input__input {
    ${InputStyle}
    height: auto;
    order: 1;
  }

  .react-tag-input__tag {
    order: 2;
    background: ${theme.color.gray};
    border-radius: 5px;
    overflow: hidden;
    align-items: middle;
    border: 0;
  }

  .react-tag-input__tag__content {
    padding: 0.4em 0.75em;
    color: ${theme.color.secondary};
    transition: all 250ms ease-in-out;

    &:focus {
      background: white;
    }
  }

  .react-tag-input__tag__remove {
    background: none;
    
    &:before,
    &:after {
      background: ${theme.color.secondary};
    }
  }
`;
