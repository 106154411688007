import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import * as boxiconsRegular from '@styled-icons/boxicons-regular'; // eslint-disable-line
import { useTheme } from 'hooks/useTheme';

const IconWrapper = styled.span`
  display: inline-block;
  color: ${(props) => props.color};
`;

const Icon = ({
  name,
  size,
  color,
  title,
  className,
}) => {
  const { iconSize } = useTheme();

  const iconName = name && name[0].toUpperCase() + name.slice(1);
  const IconTag = boxiconsRegular[iconName] || boxiconsRegular.Error;

  if (!IconTag) return null;

  return (
    <IconWrapper color={color} className={className}>
      <IconTag size={iconSize[size]} title={title} />
    </IconWrapper>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: 'M',
  title: undefined,
  color: undefined,
  className: undefined,
};

export { Icon };
