import styled from 'styled-components/macro';

const ButtonGroup = styled.div`
  button {
    margin-left: 3px;
    border-radius: 0;
  }

  & > button:first-child {
    margin-left: 0;
    border-radius: ${({ theme: { button } }) => `${button.borderRadius} 0 0 ${button.borderRadius}`};
  }

  & > button:last-child {
    border-radius: ${({ theme: { button } }) => `0 ${button.borderRadius} ${button.borderRadius} 0`};
  }
`;

export { ButtonGroup };
