import React from 'react';
import { navigate } from '@reach/router';
import { Button } from 'components/_elements/Button';
import { routes } from 'services/routes';
import { AppFooterWrapper } from './style';

const AppFooter = () => (
  <AppFooterWrapper>
    <Button
      isPrimary
      onClick={() => navigate(routes.add)}
      icon="PlusCircle"
      size="M"
    />
  </AppFooterWrapper>
);

export { AppFooter };
